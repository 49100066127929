import {IDBPDatabase, openDB} from "idb";

export class KeyValueIndexedDb {
  private static dbInstances: { [key: string]: IndexedDbKeyValueStore } = {}

  public static getDb(name: string) {
    if (this.dbInstances[name] === undefined)
      this.dbInstances[name] = new IndexedDbKeyValueStore(name);

    return this.dbInstances[name];
  }
}

export class IndexedDbKeyValueStore {
  private dbInstance: Promise<IDBPDatabase>;
  
  constructor(name: string) {
    this.dbInstance = openDB(name, 1, {
      upgrade(db) {
        db.createObjectStore("items")
      }
    });
  }

  public async get(key: any) {
    return (await this.dbInstance).get("items", key);
  }
  public async set(key: any, val: any) {
    return (await this.dbInstance).put("items", val, key);
  }
  public async delete(key: any) {
    return (await this.dbInstance).delete("items", key);
  }
  public async clear() {
    return (await this.dbInstance).clear("items");
  }
  public async keys() {
    return (await this.dbInstance).getAllKeys("items");
  }
}