// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Constants {
  export const TdmDb = {
    Variables: "TdmVariables",
    BaseUrl: "TdmBaseUrl",
    AccessToken: "TdmAccessToken",
  };
  
  export const TdmCookies  = {
    BaseUrl: "hostOverride",
    AccessToken: "TeosTdmAccessToken"
  };
}
