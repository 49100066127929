import React, {useEffect, useState} from "react";
import {HttpConnection} from "shared/Designer/RequestCreator";
import styles from "./Menu.scss";
import {useTranslation} from "react-i18next";
import Avatar from "sharedReact/Avatar";
import {EnvironmentDto} from "shared/Generated/Dto/EnvironmentDto";
import {TdmApi} from "shared/Api/TdmApi";
import {ActionPermission} from "shared/Generated/ActionPermission";
import ReactDOMClient from "react-dom/client";
import ComingSoon from "sharedReact/ComingSoon";
import {Defaults} from "shared/Tdm/Defaults";
import {Cookie} from "shared/Util/Cookie";
import {LicensePermission} from "shared/Generated/LicensePermission";
import {Badge, Popover} from "antd";
import {toDate} from "date-fns";
import BellIcon from "sharedReact/Site/BellIcon";


type MenuItem = {
  name: string;
  title: string;
  icon?: string;
  items: {
    name: string,
    path: string,
    contains?: string[],
    permissions?: ActionPermission[],
    licensePermissions?: LicensePermission[]
  }[];
  path?: string;
  comingSoon?: boolean;
}

const menuItemsOutsideEnvironment: MenuItem[] = [
  {
    name: "dashboard",
    title: "My Environments",
    icon: "application",
    path: "environments",
    items: []
  },
  {
    name: "licensePlan",
    title: "License plan",
    icon: "licensing",
    path: "licenses/plan",
    items: []
  }
];

const menuItemsInsideEnvironment: MenuItem[] = [
  {
    name: "dashboard",
    title: "Dashboard",
    icon: "application",
    path: "dashboard",
    items: []
  },
  {
    name: "designer",
    title: "Designer",
    icon: "palette",
    items: [
      {name: "Players", path: "players", contains: ["playerGroups"], permissions: [ActionPermission.PlayerOverview], licensePermissions: [LicensePermission.PlaySignage]},
      {name: "Pages", path: "pages", permissions: [ActionPermission.PageOverview], licensePermissions: [LicensePermission.PlaySignage]},
      {name: "Playlists", path: "playlists", permissions: [ActionPermission.PlaylistOverview], licensePermissions: [LicensePermission.PlaySignage]},
      {name: "Media", path: "media", permissions: [ActionPermission.MediaOverview], licensePermissions: [LicensePermission.PlaySignage]},
      {name: "Schedules", path: "schedules", permissions: [ActionPermission.ScheduleOverview], licensePermissions: [LicensePermission.PlaySignage]},
      {
        name: "Fonts",
        path: "fontFamilies",
        permissions: [ActionPermission.FontOverview],
        licensePermissions: [LicensePermission.CustomFonts]
      },
      {
        name: "Content log",
        path: "contentLog",
        permissions: [ActionPermission.ContentLog],
        licensePermissions: [LicensePermission.ContentLog]
      }
    ]
  },
  {
    name: "interactions",
    title: "Interactions",
    icon: "data",
    items: [
      {name: "Data sources", path: "dataSources", permissions: [ActionPermission.DataSourceOverview], licensePermissions: [LicensePermission.PlaySignage]},
      {
        name: "Interactions",
        path: "interactions",
        permissions: [ActionPermission.InteractionOverview],
        licensePermissions: [LicensePermission.Interactivity]
      },
    ],
  },
  /*{
    name: "roombooking",
    title: "Room booking",
    icon: "cube",
    items: [],
    comingSoon: true
  }*/
];

//ReactComponentIgnore
export const MenuWrapper: React.FC = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>(menuItemsOutsideEnvironment);
  const [expandedSubmenu, setExpandedSubmenu] = useState<string>("");
  const [expandedMenu, setExpandedMenu] = useState<string>("");
  const [environment, setEnvironment] = useState<EnvironmentDto>(null);
  const {t} = useTranslation();
  const [licenseNotification, setLicenseNotification] = useState<{title: React.ReactNode, description: React.ReactNode, icon: React.ReactNode, isDemo?: boolean}>(null);
  
  const getEnvironmentCookie = () => {
    return Cookie.getCookie("lastVisitedEnvironmentPath");
  };

  useEffect(() => {
    if(environment != null)
      setMenuItems(menuItemsInsideEnvironment);
    else
      setMenuItems(menuItemsOutsideEnvironment);
  }, [environment]);

  useEffect(() => {
    const environmentCookie = getEnvironmentCookie();
    if(environmentCookie != null) {
      TdmApi.environments.getByPath(environmentCookie)
        .then((env) => {
          setEnvironment(env);
        }).catch(() => {
        setEnvironment(null);
      });
    } else {
      setEnvironment(null);
    }
    
    TdmApi.licenses.getLicensePlan().then(plan => {
      if(plan != null) {
        const renewalDate: Date = toDate(plan.nextRenewal);
        const now = new Date();
        const difference = renewalDate.getTime() - now.getTime();
        const differenceInDays = difference / (1000 * 3600 * 24);
        
        //todo - a more solid method to check if plan is a demo plan
        const isDemo = ["TDM-ENT-D", "TDM-ESS-D", "TDM-SMB-D"].includes(plan.sku);
          
        if(renewalDate < now) {
          setLicenseNotification({
            isDemo: isDemo,
            title: t("Your current license plan has expired."),
            icon: <BellIcon height="18px" width="18px" color='gold' animate={true}/>,
            description: t("Visit your license plan overview for more information and how to extend or upgrade your current license plan!")
          });
        } else {
          
          const status = {
            isDemo: isDemo,
            title: t("Your license will expire in {{days}} days", {days: Math.round(differenceInDays)}),
            icon: <BellIcon height="18px" width="18px" color={(differenceInDays <= 14 || isDemo) ? 'orange' : '#037703'} animate={false}/>,
            description: t("Visit your license plan overview for more information, to upgrade, or to expand your license plan with additional devices or modules")
          };
          
          setLicenseNotification(status);
        }
      } else {
        setLicenseNotification({
          isDemo: false,
          title: t("Unlock the full capabilities of TDM"),
          icon: <BellIcon height="18px" width="18px" color='gold' animate={true}/>,
          description: t("Configure your own license plan and get started with an environment of your own!")
        });
      }
    });
  }, []);

  function isMenuItemActive(item: MenuItem): boolean {
    return item.items.some(x => isSubmenuLinkCurrent(x));
  }

  function menuItemAllowed(item: MenuItem): boolean {
    const allowed =  !item.items || item.items.length == 0 || item.items.some(x => subMenuItemAllowed(x));
    return allowed;
  }

  function subMenuItemAllowed(item: { permissions?: ActionPermission[], licensePermissions?: LicensePermission[] }): boolean {
    if (item.permissions && item.permissions.length > 0 && !item.permissions.some(perm => TdmApi.allowed(perm)))
      return false;

    if (item.licensePermissions && item.licensePermissions.length > 0 && !item.licensePermissions.some(perm => TdmApi.allowedByLicense(perm)))
      return false;
    
    return true;
  }

  function isSubmenuLinkCurrent(item: { name: string, path: string, contains?: string[] }): boolean {
    const href = `/${HttpConnection.environmentPath}/${item.path}`;
    if (location.pathname.startsWith(href)) return true;
    return item.contains?.some(path => location.pathname.startsWith(`/${HttpConnection.environmentPath}/${path}`)) ?? false;
  }

  const handleSubmenuClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const submenuName = event.currentTarget.dataset.submenu;
    if (submenuName) {
      const menu = menuItems.find(x => x.name === submenuName);
      if (menu && menu.items.length > 0) {
        setExpandedSubmenu((prevState) => (prevState === submenuName ? "" : submenuName));
      } else {
        setExpandedSubmenu("");
      }
    }
  };
  const expandMenu = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const menu = event.currentTarget.dataset.menu;
    if (menu) {
      setExpandedMenu((prevState) => (prevState === menu ? "" : menu));
    }
  };

  return (
    <>
      <div className={`${styles.menu} ${expandedMenu === "mainMenu" ? styles.expanded : ""}`}>
        <div className={styles.logoWrapper}>
          {environment
            ? <Avatar environment={environment} className={styles.logo}/>
            : <a className={`${styles.logo} ${styles.placeholder}`}>
              <img alt="logo" src={Defaults.TDM_LOGO}/>
            </a>
          }
        </div>
        <div className={styles.menuItems}>
          {menuItems.map((menu, index) => menuItemAllowed(menu) &&
            (menu.comingSoon ?
              <ComingSoon key={index} functionalityName={menu.name}>
                <a
                  href={menu.path != null ? `/${environment ? environment.path + '/' : ''}${menu.path}` : null}
                  data-submenu={menu.name}
                  className={`${expandedSubmenu === menu.name ? styles.active : ""} ${isMenuItemActive(menu) ? styles.current : ""}`}
                  onClick={handleSubmenuClick}
                >
                  <i className={`icon icon-${menu.icon}`}></i>
                  <div className={styles.labelWrapper}>
                    <span className={styles.label}>{t(menu.title)}</span>
                  </div>
                </a>
              </ComingSoon>
            :
              <a href={menu.path != null ? `/${environment ? environment.path + '/' : ''}${menu.path}` : null}
                 key={index}
                 data-submenu={menu.name}
                 className={`${expandedSubmenu === menu.name ? styles.active : ""} ${isMenuItemActive(menu) ? styles.current : ""}`}
                 onClick={handleSubmenuClick}>
                <i className={`icon icon-${menu.icon}`}></i>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>{t(menu.title)}</span>
                </div>
              </a>
          ))}

          <div className={styles.menuSpacer}/>
          
          {/*<a href="#">*/}
          {/*  <i className="icon icon-trash"/>*/}
          {/*  <div className={styles.labelWrapper}><span className={styles.label}>{t("Deleted items")}</span></div>*/}
          {/*</a>*/}
          {environment && (
            licenseNotification == null ? (
              <a href={`/${environment.path}/licenses/plan`}>
                <Badge className={styles.badge} count={licenseNotification?.icon} size="small">
                  <i className="icon icon-licensing"/>
                  <div className={styles.labelWrapper}><span className={styles.label}>{t("My license")}</span></div>
                </Badge>
              </a>
            ) : (
              <Popover 
                placement="rightTop" 
                title={licenseNotification.title} 
                content={<div className={styles.licensePopupContent}>{licenseNotification.description}</div>} 
                arrow={true}
              >
                <a href={`/${environment.path}/licenses/plan`}>
                  <Badge className={styles.badge} count={licenseNotification.icon} size="small">
                    <i className="icon icon-licensing"/>
                    <div className={styles.labelWrapper}><span className={styles.label}>{t("My license")}</span></div>
                  </Badge>
                </a>
              </Popover>
          ))}
          {/*<a href="#">*/}
          {/*  <i className="icon icon-help"/>*/}
          {/*  <div className={styles.labelWrapper}><span className={styles.label}>{t("Support center")}</span></div>*/}
          {/*</a>*/}
          <a href="/account/logout">
            <i className="icon icon-logout"/>
            <div className={styles.labelWrapper}><span className={styles.label}>{t("Log out")}</span></div>
          </a>
        </div>
        <div className={styles.expandButton}>
          <a onClick={expandMenu} data-menu="mainMenu" className={styles.collapsed}><i
            className="icon icon-arrow-short"></i></a>
        </div>
      </div>
      <div className={styles.submenus}>
        {menuItems
            .filter(menu => menu.items.length > 0)
            .map((menu, index) => 
          <div
            key={`submenu-${index}`}
            className={`${styles.submenu} ${expandedSubmenu === menu.name ? styles.expanded : ""}`}
            data-submenu={menu.name}>
            <div className={styles.submenuContent}>
              <div className={styles.submenuHeader}>
                <h4>{menu.title}</h4>
              </div>
              <ul className={styles.submenuItems}>
                {menu.items.map((item, idx) =>
                  subMenuItemAllowed(item) && (
                    <a key={`submenu-${index}-${idx}`} href={`/${HttpConnection.environmentPath}/${item.path}`} className={isSubmenuLinkCurrent(item) ? styles.current : ''}>
                      <span>{item.name}</span>
                    </a>
                  )
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const MenuRenderer = {
  render: () => {
    const menuWrapper = document.getElementById('menu-wrapper') as HTMLDivElement;
    if (menuWrapper != null) {
      const wrapperContainer = ReactDOMClient.createRoot(menuWrapper);
      wrapperContainer.render(<MenuWrapper/>);
    }
  }
};

export {MenuRenderer};