import {ActionPermission} from "shared/Generated/ActionPermission";

export class ActionPermissionService {
  private static permissions: Uint8Array;

  private static allPermissions?: Record<string, string>;

  public async fetchAllPermissions(): Promise<void> {
    if (!ActionPermissionService.allPermissions) {
      ActionPermissionService.allPermissions = await fetch("/users/json/allActionPermissions").then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json() as Promise<Record<string, string>>;
      });
    }
  }

  public allowedForEnvironment(permission: ActionPermission, environment: string) {
    if (!ActionPermissionService.allPermissions) {
      throw new Error("Call fetchAllPermissions before calling allowedForEnvironment");
    }

    const permString = ActionPermissionService.allPermissions?.[environment];
    if (!permString) return false;

    const perms = Uint8Array.from(atob(permString), c => c.charCodeAt(0));

    return this.hasActionPermission(perms, permission);
  }

  public allowed(permission: ActionPermission) {
    if (!ActionPermissionService.permissions) {
      const fromDom = document.querySelector("#react-component-user-action-permissions") as HTMLInputElement;

      if (!fromDom) {
        console.log("Action permissions checked, but page has no input with the user's permissions");
        return false;
      }

      ActionPermissionService.permissions = Uint8Array.from(atob(fromDom.value), c => c.charCodeAt(0));
    }

    return this.hasActionPermission(ActionPermissionService.permissions, permission);
  }

  private hasActionPermission(set: Uint8Array, action: ActionPermission): boolean {
    //Permissions are stored as an "array of booleans", where the int value of the permission enum represent the bit
    // offset in this array. It's not a perfectly efficient system, as we deliberately leave gaps in the enum between
    // sections for future expansion, but I think you'll agree that this is way more efficient than representing each
    // permission as a boolean in JSON.

    const offset = action as number;
    const byteOffset = ~~(offset / 8);
    const bitOffset = offset % 8;

    const byte = set[byteOffset];
    return (byte & (1 << bitOffset)) != 0;
  }
}