import {ContentLogQueryDto} from "shared/Generated/Dto/ContentLogQueryDto";
import {HttpConnection} from "../Designer/RequestCreator";
import {ContentLogQueryResultDto} from "shared/Generated/Dto/ContentLogQueryResultDto";

export class ContentLogController {
  public async filterValues(): Promise<ContentLogQueryDto> {
    return HttpConnection.getAsync(null, "contentLog/json/filterValues", true);
  }

  public async query(query: ContentLogQueryDto): Promise<ContentLogQueryResultDto> {
    return HttpConnection.postAsync(query, "contentLog/json/query", true);
  }
}