import {EnvironmentDto} from "shared/Generated/Dto/EnvironmentDto";
import {HttpConnection} from "../Designer/RequestCreator";
import {EnvironmentValidationDto} from "shared/Generated/Dto/EnvironmentValidationDto";
import {MqttCredentialsDto} from "shared/Generated/Dto/MqttCredentialsDto";
import {CustomerDto} from "shared/Generated/Dto/Licenses/CustomerDto";

export class EnvironmentApiController {
  private readonly route = `environments/json`;

  public getAll(): Promise<EnvironmentDto[]> {
    return HttpConnection.getAsync("", this.route, false) as Promise<EnvironmentDto[]>;
  }

  public get(id: string): Promise<EnvironmentDto> {
    return HttpConnection.getAsync(id, this.route, false) as Promise<EnvironmentDto>;
  }

  public getByPath(environmentPath: string): Promise<EnvironmentDto> {
    return HttpConnection.getAsync(environmentPath, `${this.route}/getByPath`, false) as Promise<EnvironmentDto>;
  }

  public post(dto: EnvironmentDto): Promise<EnvironmentDto> {
    return HttpConnection.postAsync(dto, this.route, false) as Promise<EnvironmentDto>;
  }

  public put(dto: EnvironmentDto, id: string): Promise<EnvironmentDto> {
    return HttpConnection.putAsync(dto, `${this.route}/${id}`, false) as Promise<EnvironmentDto>;
  }

  public validate(dto: EnvironmentDto): Promise<EnvironmentValidationDto> {
    return HttpConnection.postAsync(dto, `${this.route}/validate`, false) as Promise<EnvironmentValidationDto>;
  }

  public getDesignerCredentials(): Promise<MqttCredentialsDto> {
    if (!location.protocol.startsWith("https")) {
      return this.getDesignerCredentialsPlain();
    }

    return new Promise((resolve, reject) => {
      HttpConnection.getAsync(null, `${this.route}/GetDesignerCredentials`).then(async credentials => {
        const bytes = await credentials.arrayBuffer();
        const int32 = new Int32Array(bytes);
        const ivLength = int32[0];
        const hashLength = int32[1];
        const messageLength = int32[2];
        
        const uint8 = new Uint8Array(bytes.slice(12));
        const iv = uint8.slice(0, ivLength);
        const hash = uint8.slice(ivLength, ivLength + hashLength);
        const message = uint8.slice(ivLength + hashLength, ivLength + hashLength + messageLength);
        
        const settings: AesCbcParams = {name: "AES-CBC", iv: iv};
        const encoder = new TextEncoder();
        const rawKey = encoder.encode("ecq5azg6ukm!WQA6ueb");

        const baseKey = await crypto.subtle.importKey("raw", rawKey,{name: "PBKDF2"},false,["deriveBits", "deriveKey"]);
        const key =  await crypto.subtle.deriveKey({name: "PBKDF2", hash: "SHA-256", salt: iv, iterations: 1000}, baseKey, {name: "AES-CBC", length: 256}, true, ["encrypt", "decrypt"]);
        
        crypto.subtle.decrypt(settings, key, message).then(encodedCredentials => {
          const decoder = new TextDecoder();
          const jsonString = decoder.decode(encodedCredentials);
          resolve(JSON.parse(jsonString));
        }).catch(() => {
          reject();
        });
      }).catch(error => {
        reject(error);
      });
    });
  }

  private getDesignerCredentialsPlain(): Promise<MqttCredentialsDto> {
    return HttpConnection.getAsync(null, `${this.route}/GetDesignerCredentialsPlain`) as Promise<MqttCredentialsDto>;
  }

  public getCustomer(): Promise<CustomerDto> {
    return HttpConnection.getAsync(null, `${this.route}/GetCustomer`) as Promise<CustomerDto>;
  }

  public async join(invitationId: string): Promise<any> {
    return await HttpConnection.postAsync({}, `${this.route}/join/${invitationId}`, false);
  }

  public async getAvatar(environmentId: string) {
    return await HttpConnection.getAsync("", `environments/${environmentId}/avatar`, false);
  }

  public async uploadAvatar(environmentId: string, formData: FormData): Promise<any> {

    return fetch(`/environments/${environmentId}/uploadAvatar`, {
      method: 'POST',
      body: formData
    });
  }

  public search(query: string): Promise<EnvironmentDto[]> {
    return HttpConnection.getAsync(query, `${this.route}/search`, false);
  }
  
  public async getNotifications(environmentId: string): Promise<any> {
    return HttpConnection.getAsync(null, `${this.route}/${environmentId}/notifications`, false);
  }
}