import {JsonApiController} from "./JsonApiController";
import {HttpConnection} from "../Designer/RequestCreator";
import {FontDto} from "shared/Generated/Dto/FontDto";
import {FontFamilyDto} from "shared/Generated/Dto/FontFamilyDto";

export class FontApiController extends JsonApiController<FontDto> {
  public constructor() {
    super("fonts");
  }

  public async uploadFont(formData: FormData, fontFamilyId?: string): Promise<FontFamilyDto> {
    const addition = fontFamilyId != null ? `?fontFamilyId=${fontFamilyId}` : "";

    const response = await fetch(`${HttpConnection.hostOverride}/${HttpConnection.environmentPath}/${this.route}/uploadFont${addition}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    });
    return await response.json();
  }

  public async setDefaultFonts(): Promise<FontFamilyDto[]> {
    return await HttpConnection.getAsync(null, `${this.route}/SetDefaultFonts`) as FontFamilyDto[];
  }
}

