export class TdmUrl {
  public static root = `${window.location.protocol}//${window.location.host}/`;
  public static account = {
    login: (returnUrl?: string) => `/account/login${returnUrl ? "?returnUrl=" + encodeURIComponent(returnUrl) : ""}`,
    register: (returnUrl?: string) => `/account/register${returnUrl ? "?returnUrl=" + encodeURIComponent(returnUrl) : ""}`
  };
  private static currentEnvironment: string = window.location.pathname.split('/')[1];
  public static environments = {
    environments: '/environments',
    join: (invitationId: string, environment?: string) => `${environment ?? TdmUrl.currentEnvironment}/join/${invitationId}`
  };
}