import {JsonApiController} from "./JsonApiController";
import {EnvironmentInvitationDto} from "shared/Generated/Dto/EnvironmentInvitationDto";
import {DataSourceApiController} from "./DataSourceApiController";
import {UserRoleDto} from "shared/Generated/Dto/UserRoleDto";
import {MediaApiController} from "./MediaApiController";
import {PlayerApiController} from "./PlayerApiController";
import {EnvironmentApiController} from "./EnvironmentApiController";
import {UserApiController} from "./UserApiController";
import {PlayerGroupDto} from "shared/Generated/Dto/PlayerGroupDto";
import {FileSystemApiController} from "./FileSystemApiController";
import {ContentController} from "./ContentController";
import {ScheduleDto} from "shared/Generated/Dto/Schedule/ScheduleDto";
import {ElementDto} from "shared/Generated/Dto/ElementDto";
import {EnvironmentInvitationApiController} from "./EnvironmentInvitationApiController";
import {HttpConnection} from "../Designer/RequestCreator";
import {SearchResultDto} from "shared/Generated/Dto/SearchResultDto";
import {FontFamilyDto} from "shared/Generated/Dto/FontFamilyDto";
import {FontApiController} from "./FontController";
import {DataSourceDto} from "shared/Generated/Dto/DataSources/DataSourceDto";
import {ResourcePermissionController} from "./ResourcePermissionController";
import {ConnectionController} from "./ConnectionController";
import {CalendarController} from "./CalendarController";
import {ActionPermission} from "shared/Generated/ActionPermission";
import {ActionPermissionService} from "./ActionPermissionService";
import {MediaItemDto} from "shared/Generated/Dto/MediaItems/MediaItemDto";
import {PlaylistController} from "./PlaylistController";
import {UtilController} from "./UtilController";
import {LicensePermission} from "shared/Generated/LicensePermission";
import {LicenseController} from "./LicenseController";
import {InteractionController} from "./InteractionController";
import {ContentLogController} from "./ContentLogController";
import {PageApiController} from "shared/Api/PageController";
import {PageFolderApiController} from "shared/Api/PageFolderApiController";
import {PlaylistFolderApiController} from "shared/Api/PlaylistFolderApiController";
import {VoucherController} from "shared/Api/VoucherController";

export class TdmApi {
  public static players = new PlayerApiController();
  public static playerGroups = new JsonApiController<PlayerGroupDto>("playerGroups");
  public static playlists = new PlaylistController();
  public static playlistFolders = new PlaylistFolderApiController();
  public static pages = new PageApiController();
  public static pageFolders = new PageFolderApiController();
  public static elements = new JsonApiController<ElementDto>('elements');
  public static media = new MediaApiController<MediaItemDto>('media');
  public static dataSources = new DataSourceApiController<DataSourceDto>('dataSources');
  public static environments = new EnvironmentApiController();
  public static environmentInvitations = new EnvironmentInvitationApiController<EnvironmentInvitationDto>("invitations");
  public static users = new UserApiController();
  public static userRoles = new JsonApiController<UserRoleDto>('userRoles');
  public static content = new ContentController();
  public static fileSystem = new FileSystemApiController();
  public static schedules = new JsonApiController<ScheduleDto>("schedules");
  public static fonts = new FontApiController();
  public static fontFamilies = new JsonApiController<FontFamilyDto>("fontFamilies");
  public static connections = new ConnectionController();
  public static calendars = new CalendarController();
  public static util = new UtilController();
  public static interactions = new InteractionController();
  public static search = (query: string) => HttpConnection.getAsync(null, 'search?q=' + encodeURIComponent(query)) as Promise<SearchResultDto[]>;
  public static resourcePermissions = new ResourcePermissionController();
  public static actions = new ActionPermissionService();
  public static licenses = new LicenseController();
  public static contentLog = new ContentLogController();
  public static vouchers = new VoucherController();

  static allowed(permission?: ActionPermission) {
    if (!permission) return true;

    return TdmApi.actions.allowed(permission);
  }

  static allowedByLicense(permission?: LicensePermission) {
    if (!permission) return true;

    return TdmApi.licenses.allowed(permission);
  }
}