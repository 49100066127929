import {MenuRenderer} from './Menu';
import {getPageFunction} from './Pages';

function executePageFunction() {
  // @ts-expect-error ignore the getCurrentPageName function not being defined, as it is implemented in the layout
  const currentPage = getCurrentPageName && getCurrentPageName();
  if (currentPage != "") {
    const curMenuItem = document.querySelectorAll(".header .menu-item[data-page=" + currentPage + "]");
    if (curMenuItem != null)
      curMenuItem.forEach(x => x.classList.add("active"));

    const pageFunction = getPageFunction(currentPage);
    if (pageFunction) {
      pageFunction();
    }
  }
}


MenuRenderer.render();
document.addEventListener('DOMContentLoaded', executePageFunction);
