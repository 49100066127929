import React, {createContext, useContext, useEffect, useState} from "react";
import {TdmApi} from "shared/Api/TdmApi";
import {HttpConnection} from "../RequestCreator";
import {EnvironmentDto} from "shared/Generated/Dto/EnvironmentDto";

interface EnvironmentContextProps {
  children: React.ReactNode;
}

type EnvironmentContextType = {
  environment?: EnvironmentDto;
  updateEnvironment: (environmentDto: EnvironmentDto) => void;
}

const EnvironmentContext = createContext<EnvironmentContextType>({} as EnvironmentContextType);

const EnvironmentContextProvider: React.FC<EnvironmentContextProps> = ({children}) => {
  const [environment, setEnvironment] = useState<EnvironmentDto>(null);

  useEffect(() => {
    if (HttpConnection.environmentPath !== null)
      TdmApi.environments.getByPath(HttpConnection.environmentPath).then(setEnvironment);
  }, [HttpConnection.environmentPath]);

  function updateEnvironment(environmentDto: EnvironmentDto) {
    setEnvironment(environmentDto);
  }


  const context: EnvironmentContextType = {
    environment: environment,
    updateEnvironment: updateEnvironment,
  };

  return (
    <EnvironmentContext.Provider value={context}>
      {children}
    </EnvironmentContext.Provider>
  );
};

const useEnvironment = () => useContext(EnvironmentContext);

export {useEnvironment};
export default EnvironmentContextProvider;