import {JsonApiController} from "./JsonApiController";
import {HttpConnection} from "../Designer/RequestCreator";
import {PageFolderDto} from "shared/Generated/Dto/PageFolderDto";
import {PlaylistFolderDto} from "shared/Generated/Dto/PlaylistFolderDto";

export class PlaylistFolderApiController extends JsonApiController<PlaylistFolderDto> {
  public constructor() {
    super("playlistFolders");
  }

  public async getBreadCrumb(folderId: string): Promise<PlaylistFolderDto[]> {
    return await HttpConnection.getAsync("", `${this.route}/${folderId}/breadCrumb`) as Promise<PlaylistFolderDto[]>;
  }
}

