import {JsonApiController} from "./JsonApiController";
import {HttpConnection} from "../Designer/RequestCreator";
import {PageDto} from "../Generated/Dto/PageDto";

export class PageApiController extends JsonApiController<PageDto> {
    public constructor() {
        super("pages");
    }

    public async importPage(formData: FormData): Promise<PageDto> {
        const response = await fetch(`/${HttpConnection.environmentPath}/${this.route}/importPage`, {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
            },
        });
        return await response.json();
    }
    
    
    public async exportPage(pageId: string): Promise<void>{

        const response = await fetch(`/${HttpConnection.environmentPath}/${this.route}/exportPage/${pageId}`);

        if (!response.ok) {
            throw new Error(`Failed to download file: ${response.statusText}`);
        }

        const blob = await response.blob();

        // Create a link element
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

         
        // Set the filename for the downloaded file
        const filename = this.extractFilenameFromHeaders(response);
        link.setAttribute('download', filename);

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        
        return null;
    }


    public extractFilenameFromHeaders(response: Response): string {
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition && contentDisposition.includes('attachment')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }
        // Fallback: generate a filename
        return `exported_file_${Date.now()}.pdf`;
    }

}

