import React, {useEffect, useState} from "react";
import styles from "./EditableImage.scss";
import {useTranslation} from "react-i18next";

interface EditableImageProps {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fallbackElement?: React.ReactNode;
  imageClassName?: string;
}

const EditableImage: React.FC<EditableImageProps> = ({
                                                       name,
                                                       value = "",
                                                       onChange,
                                                       fallbackElement = null,
                                                       imageClassName = ""
                                                     }) => {
  const [source, setSource] = useState(value ?? "");
  const {t} = useTranslation();

  useEffect(() => {
    setSource(value);
  }, [value]);

  function onFileSelected(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files.length > 0) {
      setSource(URL.createObjectURL(files[0]));
      onChange(event);
    }
  }

  return (
    <label className={`${styles.imageWrapper} ${imageClassName}`}>
      {!source && fallbackElement ? fallbackElement : <img src={source} alt={t("Avatar")}/>}
      <div className={styles.overlay}>
        <i className={"icon icon-edit"}/>
      </div>
      <input className={styles.input}
             type={"file"}
             accept={"image/*"}
             onChange={onFileSelected}
             value={undefined}
             name={name}
             hidden
      />
    </label>
  );
};

export default EditableImage;