import {JsonApiController} from "./JsonApiController";
import {InteractionDto} from "shared/Generated/Dto/Interactions/InteractionDto";
import {HttpConnection} from "../Designer/RequestCreator";

export class InteractionController extends JsonApiController<InteractionDto> {
  public constructor() {
    super("interactions");
  }

  public async updateToken(id: string): Promise<string> {

    console.log(this.route);
    const response = await fetch(`/${HttpConnection.environmentPath}/${this.route}/updateToken/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    return await response.json();
  }
}