import React, {useState} from "react";
import styles from "./LanguageSelector.scss";
import {SupportedLanguages} from "shared/Util/SupportedLanguages";
import i18next from "shared/Util/i18next";
import ReactFlagsSelect from "react-flags-select";


const LanguageSelector: React.FC = () => {
  const [selected, setSelected] = useState(SupportedLanguages.getFlagCodeByCountyCode(i18next.language));
  const changeLanguages = (flagCode: string) => {
    i18next.changeLanguage(SupportedLanguages.getLanguageCodeByFlagCode(flagCode)).then(() => {
      setSelected(flagCode);
    });
  };

  return (
    <ReactFlagsSelect
      className={styles.LanguageSelect}
      countries={SupportedLanguages.getCountryFlagCode()}
      customLabels={SupportedLanguages.getCountryLabels()}
      showOptionLabel={true}
      showSelectedLabel={false}
      optionsSize={16}
      fullWidth={true}
      selected={selected}
      onSelect={(flagCode) => changeLanguages(flagCode)}
    />
  );
};

export default LanguageSelector;