/* eslint-disable @typescript-eslint/no-var-requires */
const tdmLogo = "/TdmStaticFiles/Img/shared/logo-tdm-sm.svg";
const tdmLogoDark = "/TdmStaticFiles/Img/shared/logo-tdm.svg";
const teosLogo = "/TdmStaticFiles/Img/shared/logo-teos-sm.svg";
const teosLogoDark = "/TdmStaticFiles/Img/shared/logo-teos.svg";
const defaultImage = "/TdmStaticFiles/Img/shared/template-placeholder.svg";
const powerPointThumbnail = "/TdmStaticFiles/Img/shared/icons/toolbar/icon-powerpoint.svg";
const audioThumbnail = "/TdmStaticFiles/Img/shared/icons/toolbar/icon-audio.svg";
const videoThumbnail = "/TdmStaticFiles/Img/shared/icons/toolbar/icon-video.svg";
const folderImage = "/TdmStaticFiles/Img/shared/icons/designer/icon-folder.svg";
export {defaultImage};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Defaults {
  export const TDM_LOGO = tdmLogo;
  export const TDM_LOGO_DARK = tdmLogoDark;
  export const TEOS_LOGO = teosLogo;
  export const TEOS_LOGO_DARK = teosLogoDark;
  export const IMAGE_URL = defaultImage;
  export const FOLDER_URL = folderImage;
  export const POWERPOINT_THUMBNAIL = powerPointThumbnail;
  export const AUDIO_THUMBNAIL = audioThumbnail;
  export const VIDEO_THUMBNAIL = videoThumbnail;
}
