import React, {useEffect, useState} from "react";
import {TdmApi} from "shared/Api/TdmApi";
import EditableImage from "./EditableImage";
import {stringToHslColor} from "./AccountWidget";
import {UserDto} from "shared/Generated/Dto/UserDto";
import {EnvironmentDto} from "shared/Generated/Dto/EnvironmentDto";
import styles from "./Avatar.scss";
import {Defaults} from "shared/Tdm/Defaults";

interface AvatarProps {
  user?: UserDto;
  environment?: EnvironmentDto;
  className: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Avatar: React.FC<AvatarProps> = ({user, environment, className, onChange = null}) => {
  const [source, setSource] = useState("");
  const [name, setName] = useState("");
  const [avatarFetched, setAvatarFetched] = useState(false);

  function fetchAvatar(controller: "users" | "environments", id: string) {
    if (!avatarFetched) {
      TdmApi[controller].getAvatar(id)
        .then(() => setSource(`/${controller}/${id}/avatar/`))
        .catch(() => setSource(""))
        .finally(() => setAvatarFetched(true));
    }
  }

  function refresh() {
    if (user) {
      fetchAvatar("users", user.id);
      setName(user.name);
    } else if (environment) {
      fetchAvatar("environments", environment.id);
      setName(environment.name);
    } else
      console.error("You must set either an environment or a user");
  }

  useEffect(refresh, [user, environment]);

  let initials = "";
  let bgColor = "";
  if (name) {
    bgColor = stringToHslColor(name ?? "", 80, 60);
    const names = name.replace(/\s*$/g, "").split(" ");
    initials = names.map((n, index) => index === 0 || index === names.length - 1 ? n[0] : "").join('').toUpperCase().substring(0, 2);
  }

  function FallbackSvg() {
    if (user)
      return (
        <svg className={onChange === null ? className : ""} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
          <rect fill={bgColor} width="192" height="192"/>
          <text fill="#FFF" fontFamily="'Aktiv Grotesk', sans-serif" fontSize="90px" fontWeight="700" x="50%" y="50%"
                dominantBaseline="mathematical" textAnchor="middle">
            {initials}
          </text>
        </svg>
      );
    else
      return (
        <div className={className} style={{backgroundColor: bgColor}}>
          <img src={Defaults.TDM_LOGO} alt={"logo"} className={styles.environmentFallbackImage}/>
        </div>
      );
  }

  return (
    <>
      {onChange === null
        ? source ? <img className={className} src={source} alt="Avatar"/> : <FallbackSvg/>
        : <EditableImage name={"avatar"} value={source} onChange={onChange} fallbackElement={<FallbackSvg/>}
                         imageClassName={className}/>
      }
    </>
  );
};

export default Avatar;