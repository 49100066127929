import {addPageFunction} from '../Pages';
import $ from 'jquery';

addPageFunction('product', () => {
  const industriesMenu = $("#industries .menu");
  const industriesContent = $("#industries .content");
  const elementsToObserve = document.querySelectorAll('.template-information');

  industriesMenu.on('click', '.menu-item:not(.active)', ev => {
    const target = $(ev.target).data('target');
    $(industriesContent).find(target).addClass('active')
      .siblings().removeClass('active');

    $(ev.target).addClass('active').siblings().removeClass('active');
  });


  // observe when elements come into viewport, and toggle class to start animations
  const observer = new IntersectionObserver(entries => {
    entries.forEach(({isIntersecting, target}) => {
      if (isIntersecting) {
        if (target.parentElement?.classList.contains('reverse')) {
          target.classList.add(...['fade-in-right']);
        } else {
          target.classList.add(...['fade-in-left']);
        }
      } else {
        target.classList.remove(...['fade-in-right', 'fade-in-left']);
      }
    });
  });

  for (const el of elementsToObserve) {
    observer.observe(el);
  }

  const testimonialCarousel = document.getElementById('testimonial-carousel') as Element;
  const quoteImages = document.getElementsByClassName('quote-image');
  testimonialCarousel.addEventListener('slide.bs.carousel', event => {
    quoteImages[event.from].classList.remove('active');
    quoteImages[event.to].classList.add('active');
  });

  const videoElements = document.getElementsByClassName('video-container');
  for (const el of videoElements) {
    const videoEl = el.getElementsByTagName("video").item(0);
    const playButton = el.getElementsByClassName("play-button").item(0);
    playButton.addEventListener('click', () => handlePlayButton(videoEl, playButton), false);
  }

  function handlePlayButton(videoEl, playButton) {
    if (videoEl.paused) {
      playVideo(videoEl, playButton);
    } else {
      videoEl.pause();
      playButton.classList.remove("playing");
    }
  }

  async function playVideo(videoEl, playButton) {
    try {
      playButton.classList.add("playing");
      await videoEl.play();
    } catch (err) {
      console.log(err);
      playButton.classList.remove("playing");
    }
  }
});