import {HttpConnection} from "../Designer/RequestCreator";
import {JsonApiController} from "./JsonApiController";
import {NsStationDto} from "shared/Generated/Dto/NsStationDto";
import {Dashboard, Report, Tile} from "sharedReact/Designer/Properties/PowerBiProperties";
import {WeatherIconDto} from "shared/Generated/Dto/DataSources/WeatherIconDto";

export class DataSourceApiController<MediaItemDto> extends JsonApiController<MediaItemDto> {
  constructor(path) {
    super(path);
  }

  public async GetCoordinatesFromLocation(location: string): Promise<LocationResult[]> {
    return await HttpConnection.getAsync(location, `${this.route}/GetCoordinatesFromLocation`) as LocationResult[];
  }

  public async GetPowerBiAuthUrl(id: string): Promise<string> {
    return await HttpConnection.getAsync(id, `${this.route}/GetPowerBiAuthUrl`) as string;
  }

  public async getFacebookPages(dataSourceId: string): Promise<FacebookPagesDto> {
    return await HttpConnection.getAsync(null, `${this.route}/GetFacebookPages/${dataSourceId}`);
  }

  public async getNsStations(dataSourceId: string): Promise<NsStationDto[]> {
    return await HttpConnection.getAsync(null, `${this.route}/GetNsStations/${dataSourceId}`);
  }

  public GetInstagramLoginUrl(id: string): string {
    return `${HttpConnection.hostOverride}/${HttpConnection.environmentPath}/${this.route}/GetInstagramLoginUrl/${id}`;
  }

  public async FetchCustomDataSource(dataSourceId: string): Promise<ArrayBuffer> {
    const response = await fetch(`${HttpConnection.hostOverride}/${HttpConnection.environmentPath}/${this.route}/FetchCustomDataSource/${dataSourceId}`); // Replace with your actual endpoint URL
    if (response.ok) {
      return await response.arrayBuffer();
    }
  }

  public async getPowerBiDashboards(dataSourceId: string): Promise<Dashboard[]> {
    return await HttpConnection.getAsync(null, `${this.route}/GetPowerBiDashboards/${dataSourceId}`);
  }

  public async getPowerBiReports(dataSourceId: string): Promise<Report[]> {
    return await HttpConnection.getAsync(null, `${this.route}/GetPowerBiReports/${dataSourceId}`);
  }

  public async getPowerBiTiles(dataSourceId: string, dashboardId: string): Promise<Tile[]> {
    return await HttpConnection.getAsync(null, `${this.route}/GetPowerBiTiles/${dataSourceId}?dashboardId=${dashboardId}`);
  }

  public async uploadIcon(formData: FormData, dataSourceId: string, iconType: number, iconId?: string): Promise<WeatherIconDto> {
    let addition = `?ds=${dataSourceId}&iconType=${iconType}`;
    if (iconId)
      addition += `&iconId=${iconId}`;

    const response = await fetch(`${HttpConnection.hostOverride}/${HttpConnection.environmentPath}/${this.route}/UploadIcon${addition}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json'
      }
    });
    return await response.json();
  }

  public async getIcons(): Promise<WeatherIconDto[]> {
    return await HttpConnection.getAsync(null, `${this.route}/GetIcons`);
  }

  public async removeIcon(iconId: string): Promise<void> {
    return await HttpConnection.deleteAsync(iconId, `${this.route}/RemoveIcon`);
  }

  public async getRealWorksDeveloperId(): Promise<string> {
    return await HttpConnection.getAsync("", `${this.route}/getRealWorksDeveloperId`) as string;
  }
  
  public async getImportedTeosDataSourceIds(): Promise<number[]> {
    return await HttpConnection.getAsync(null, `${this.route}/importedTeosDataSources`) as number[];
  }

  public async powerBiLogin(urlParams: string): Promise<string> {
    return await HttpConnection.getAsync(null, `dataSources/PowerBiLogin${urlParams}`, false) as string;
  }
}

export type LocationResult = {
  name?: string,
  localNames?: string[],
  lat?: number,
  lon?: number,
  country?: string,
  state?: string,
}

interface CategoryListDto {
  id: string;
  name: string;
}

export interface PageDataDto {
  access_token: string;
  category: string;
  category_list: CategoryListDto[];
  name: string;
  id: string;
  tasks: string[];
}

export interface FacebookPagesDto {
  data?: PageDataDto[];
}
