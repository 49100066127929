import React, {Fragment, useEffect, useState} from "react";
import styles from "./Breadcrumb.scss";
import {useEnvironment} from "shared/Designer/Contexts/EnvironmentContext";
import {useHeader} from "shared/Designer/Contexts/HeaderContext";


const Breadcrumb: React.FC = () => {
  const {breadcrumb} = useHeader();
  const {environment} = useEnvironment();
  const [parts, setParts] = useState([]);

  useEffect(() => {
    if (breadcrumb) {
      if (breadcrumb.base === "currentEnvironment" && environment) {
        const newParts = breadcrumb.parts.map(part => ({path: `/${environment.path}/${part.path}`, name: part.name}));
        newParts.unshift({path: `/${environment.path}`, name: environment.name});
        setParts(newParts);
      } else {
        setParts(breadcrumb.parts);
      }
    }
  }, [breadcrumb, environment]);


  return (
    <div className={styles.breadcrumb}>
      {parts.map((part, index) =>
        <Fragment key={part.path}>
          <a className={styles.breadcrumbPart} href={part.path}>
            {part.name === "Home" || (environment && part.name === environment.name) ? (
              <>
                <i className="icon icon-home-bordered"></i>
                {/*<span>{part.name.toUpperCase()}</span>*/}
              </>
            ) : (
              <>
                <span>{part.name.toUpperCase()}</span>
              </>
            )}
          </a>
          <span className={styles.breadcrumbSeparator}>/</span>
        </Fragment>
      )}
    </div>
  );
};


export default Breadcrumb;
