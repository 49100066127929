import {HttpConnection} from "shared/Designer/RequestCreator";
import {VoucherResultDto} from "shared/Generated/Dto/Licenses/VoucherResultDto";

export class VoucherController {
    private route: string;
    public constructor() {
        this.route = "vouchers";
    }

    public async checkVoucherCode(code: string): Promise<VoucherResultDto> {
        return HttpConnection.getAsync(null, `${this.route}/checkVoucher?voucherCode=${code}`, false);
    }
}