import {HttpConnection} from "../Designer/RequestCreator";
import {JsonApiController} from "./JsonApiController";


export class MediaApiController<MediaItemDto> extends JsonApiController<MediaItemDto> {
  constructor(path) {
    super(path);
  }

  public async getFromStorageContent(storageProviderId: string, contentId: string): Promise<MediaItemDto> {
    return await HttpConnection.getAsync(contentId, `${this.route}/getFromStorageContent/${storageProviderId}`) as MediaItemDto;
  }
}