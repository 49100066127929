import {JsonApiController} from "./JsonApiController";
import {HttpConnection} from "../Designer/RequestCreator";
import {PlaylistDto} from "../Dto/PlaylistDto";

export class PlaylistController extends JsonApiController<PlaylistDto> {
  public constructor() {
    super("playlists");
  }

  public async wouldContainItself(playlist: PlaylistDto): Promise<boolean> {
    return await HttpConnection.postAsync(playlist, `playlists/json/wouldContainItself`);
  }
}