export class Size {
  public width: number;
  public height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  public equals(size: Size): boolean {
    return this.height == size.height && this.width == size.width;
  }
}