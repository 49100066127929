import {HttpConnection} from "../Designer/RequestCreator";
import {PlayableContentDto} from "shared/Generated/Dto/PlayableContentDto";


export class ContentController {
  protected readonly route: string;

  constructor() {
    this.route = "Content";
  }

  public async get(contentId: string): Promise<PlayableContentDto> {
    return await HttpConnection.getAsync(contentId, `${this.route}/json`);
  }
  
  public async getThumbnail(contentId: string, lastUpdatedTime: number): Promise<PlayableContentDto> {
    return await HttpConnection.getAsync(null, `${this.route}/getThumbnail/${encodeURIComponent(contentId)}?lastUpdated=${lastUpdatedTime}`);
  }
  
  public thumbnailPath(contentId: string, lastUpdatedTime: number): string {
    return HttpConnection.createUrl(null, `${this.route}/getThumbnail/${encodeURIComponent(contentId)}?lastUpdated=${lastUpdatedTime}`);
  }
}