import {LicensePlanDto} from "shared/Generated/Dto/Licenses/LicensePlanDto";
import {LicensePermission} from "shared/Generated/LicensePermission";
import {OrderDto} from "shared/Generated/Dto/Licenses/OrderDto";
import {HttpConnection} from "../Designer/RequestCreator";
import {AssignLicenseDto} from "shared/Generated/Dto/Licenses/AssignLicenseDto";
import {ExtendLicenseDto} from "shared/Generated/Dto/Licenses/ExtendLicenseDto";
import {CustomerDto} from "shared/Generated/Dto/Licenses/CustomerDto";
import {LicenseTypeDto} from "shared/Generated/Dto/Licenses/LicenseTypeDto";
import {OrderConfirmationDto} from "shared/Generated/Dto/Licenses/OrderConfirmationDto";
import {OrderOverviewDto} from "shared/Generated/Dto/OrderOverviewDto";
import {DowngradeCurrentPlanDto} from "shared/Generated/Dto/Licenses/DowngradeCurrentPlanDto";
import {ProductCategory} from "shared/Generated/ProductCategory";
import {ProductDto} from "shared/Generated/Dto/Licenses/ProductDto";

export class LicenseController {

  private static permissions: Uint8Array;

  public async getLicensePlan(): Promise<LicensePlanDto> {
    return await HttpConnection.getAsync(null, 'licenses/plan/json', false);
  }

  public async getLicenseType(id: string): Promise<LicenseTypeDto> {
    return await HttpConnection.getAsync(null, `licenses/licenseTypes/json/${id}`, false);
  }

  public async getLicenseTypesByIds(ids: string[]): Promise<LicenseTypeDto[]> {
    return await HttpConnection.postAsync(ids, `licenses/licenseTypes/json/list`, false);
  }

  public async getLicenseTypes(baseTypes: boolean): Promise<LicenseTypeDto[]> {
    return await HttpConnection.getAsync(null, `licenses/licenseTypes/json?packages=${baseTypes}`, false);
  }

  public async getDemoLicenseTypes(baseTypes: boolean): Promise<LicenseTypeDto[]> {
    return await HttpConnection.getAsync(null, `licenses/demoLicenseTypes/json?packages=${baseTypes}`, false);
  }

  public async assign(dto: AssignLicenseDto) {
    return await HttpConnection.postAsync(dto, 'licenses/assign/json');
  }

  public async extend(dto: ExtendLicenseDto) {
    return await HttpConnection.postAsync(dto, 'licenses/extend/json');
  }

  public async getCustomer(): Promise<CustomerDto> {
    return await HttpConnection.getAsync(null, 'licenses/customer/json', false);
  }

  public async putCustomer(dto: CustomerDto): Promise<CustomerDto> {
    return await HttpConnection.putAsync(dto, 'licenses/customer/json', false);
  }

  public async getProducts(category?: ProductCategory): Promise<ProductDto[]> {
    return await HttpConnection.getAsync(null, `licenses/products/json${typeof category !== "undefined" ? `?category=${category}` : ""}`, false);
  }

  public async reviewOrder(order: OrderDto): Promise<OrderOverviewDto> {
    return await HttpConnection.postAsync(order, 'licenses/orders/json/review', false);
  }

  public async reviewProductOrder(order: OrderDto): Promise<OrderOverviewDto> {
    return await HttpConnection.postAsync(order, 'licenses/ProductOrders/json/review', false);
  }

  public async placeOrder(order: OrderDto): Promise<OrderConfirmationDto> {
    return await HttpConnection.postAsync(order, 'licenses/orders/json', false);
  }

  public async placeProductOrder(order: OrderDto): Promise<OrderConfirmationDto> {
    return await HttpConnection.postAsync(order, 'licenses/productOrders/json', false);
  }

  public async activateDemoLicense(order: OrderDto): Promise<LicensePlanDto> {
    return await HttpConnection.postAsync(order, 'licenses/activateDemoLicense', false);
  }
  
  public async downgrade(dto: DowngradeCurrentPlanDto): Promise<LicensePlanDto> {
    return await HttpConnection.postAsync(dto, 'licenses/plan/downgrade', false);
  }
  
  public async cancelDowngrade(dto: DowngradeCurrentPlanDto): Promise<LicensePlanDto> {
    return await HttpConnection.postAsync(dto, 'licenses/plan/downgrade/cancel', false);
  }

  public async cancelLicensePlan(): Promise<LicensePlanDto> {
    return await HttpConnection.postAsync(null, 'licenses/plan/cancel', false);
  }

  public async uncancelLicensePlan(): Promise<LicensePlanDto> {
    return await HttpConnection.postAsync(null, 'licenses/plan/uncancel', false);
  }

  public async cancelModules(moduleIds: string[]): Promise<LicensePlanDto> {
    return await HttpConnection.postAsync(moduleIds, 'licenses/plan/cancelModules', false);
  }

  public async uncancelModules(moduleIds: string[]): Promise<LicensePlanDto> {
    return await HttpConnection.postAsync(moduleIds, 'licenses/plan/uncancelModules', false);
  }
  
  public async getNextPaymentInfo(id: string): Promise<OrderOverviewDto> {
    return await HttpConnection.getAsync(id, 'licenses/orders/json/next', false);
  }

  public async getOrder(id: string): Promise<OrderDto> {
    return await HttpConnection.getAsync(id, 'licenses/orders/json', false);
  }

  public async validateVatNumber(vatNumber: string): Promise<boolean> {
    return await HttpConnection.getAsync(vatNumber, 'licenses/vat/json', false);
  }

  public allowed(permission: LicensePermission): boolean {
    if (!LicenseController.permissions) {
      const fromDom = document.querySelector("#react-component-user-license-permissions") as HTMLInputElement;

      if (!fromDom) {
        console.log("License permissions checked, but page has no input with the user's permissions");
        return false;
      }

      LicenseController.permissions = Uint8Array.from(atob(fromDom.value), c => c.charCodeAt(0));
    }

    return this.hasLicensePermission(LicenseController.permissions, permission);
  }

  public async allowedForUser(permission: LicensePermission): Promise<boolean> {
    const json = await HttpConnection.getAsync(null, 'licenses/permissions', false) as { permissionsBase64: string };
    const permissions = Uint8Array.from(atob(json.permissionsBase64), c => c.charCodeAt(0));

    return this.hasLicensePermission(permissions, permission);
  }

  private hasLicensePermission(set: Uint8Array, action: LicensePermission): boolean {
    //Permissions are stored as an "array of booleans", where the int value of the permission enum represent the bit
    // offset in this array. It's not a perfectly efficient system, as we deliberately leave gaps in the enum between
    // sections for future expansion, but I think you'll agree that this is way more efficient than representing each
    // permission as a boolean in JSON.

    const offset = action as number;
    const byteOffset = ~~(offset / 8);
    const bitOffset = offset % 8;

    const byte = set[byteOffset];
    return (byte & (1 << bitOffset)) != 0;
  }
}