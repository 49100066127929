import React, {useEffect, useRef, useState} from 'react';
import {UserDto} from "shared/Generated/Dto/UserDto";
import styles from './AccountWidget.scss';
import {TdmUrl} from "shared/Dto/TdmUrl";
import {TdmApi} from "shared/Api/TdmApi";
import {useTranslation} from "react-i18next";
import Avatar from "./Avatar";
import {Popover} from "antd";
import RecentEnvironments from "./RecentEnvironments";
import SearchBar from "./SearchBar";
import Breadcrumb from "./Breadcrumb";
import {useHeader} from "shared/Designer/Contexts/HeaderContext";
import LanguageSelector from "sharedReact/LanguageSelector";
import {useTour} from "shared/Designer/Contexts/TourContext";

const AccountWidget: React.FC = () => {
  const {header} = useHeader();
  const [open, setOpen] = useState(false);

  useEffect(() => {

  }, []);

  return (
    <>
      {header.enabled && (
        <>
          {header.compact ? (
            <>
              <div className={`${styles.headerContainer} ${open ? styles.expanded : ''}`}>
                <div className={styles.backdrop}/>
                <div className={styles.expandHandle} onClick={() => setOpen(!open)}>
                  <i className="icon icon-arrow-down-solid"></i>
                </div>
                <div className={styles.headerWrapper}>
                  <div id="account-wrapper">
                    <Breadcrumb/>
                    <SearchBar/>
                    <div className={styles.AccountWidgetWrapper}>
                      {/*<button className={styles.AccountConfig}>*/}
                      {/*  <i className="icon icon-config"></i>*/}
                      {/*</button>*/}
                      <AccountActions/>
                      <LanguageSelector/>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div id="account-wrapper">
                <Breadcrumb/>
                <SearchBar/>
                <div className={styles.AccountWidgetWrapper}>
                  {/*<button className={styles.AccountConfig}>*/}
                  {/*  <i className="icon icon-config"></i>*/}
                  {/*</button>*/}
                  <AccountActions/>
                  <LanguageSelector/>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

//ReactComponentIgnore
export const AccountActions: React.FC = () => {
  const [user, setUser] = useState<UserDto>();
  const [isAccountDropdownVisible, setIsAccountDropdownVisible] = useState(false);
  const accountActionsRef = useRef(null);
  const accountRef = useRef(null);
  const {t} = useTranslation();
  const {setAccountTarget, isShowingTour} = useTour();
  useEffect(() => {
    TdmApi.users.getCurrentUser().then(setUser);
  }, []);

  useEffect(() => {
    if(isShowingTour){
      setAccountTarget(accountRef.current);
    }
  }, [isShowingTour]);

  const toggleAccountDropdown = () => {
    setIsAccountDropdownVisible(!isAccountDropdownVisible);
  };

  const handleClickOutside = (e: Event) => {
    if (accountActionsRef.current && !accountActionsRef.current.contains(e.target)) {
      setIsAccountDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <>
      {user &&
        <Popover rootClassName="react-component-root" placement={"bottom"} content={<AccountPopover user={user}/>} trigger={"click"} destroyTooltipOnHide>
          <div className={styles.AccountActionWrapper} ref={accountRef}>
            <a onClick={toggleAccountDropdown} className={styles.AccountActionDropdownBtn}>
              <div className={styles.AvatarWrapper}>
                <Avatar className="avatar" user={user}/>
              </div>
              <div className={styles.UserName}>
                <span>{user.name}</span>
              </div>
            </a>
          </div>
        </Popover>
      }
    </>
  );
};

interface accountPopoverProps {
  user: UserDto;
}

const AccountPopover: React.FC<accountPopoverProps> = ({user}) => {
  const {t} = useTranslation();
  const [environmentsOpen, setEnvironmentsOpen] = useState(false);
  const [envSearchFocused, setEnvSearchFocused] = useState(false);

  function onOpenChange(newOpen: boolean) {
    if (!envSearchFocused)
      setEnvironmentsOpen(newOpen);
  }

  return (
    <div id="AccountActions" className={styles.AccountActionDropdown} onClick={() => setEnvironmentsOpen(false)}>
      <div className="AvatarWrapper d-flex mb-2">
        <Avatar className={styles.AvatarLarge} user={user}/>
      </div>
      <span className="d-block fw-bold text-center px-2 mb-3">{user.name}</span>
      <ul>
        <Popover rootClassName="react-component-root" placement={"left"}
                 content={<RecentEnvironments onSearchFocusChanged={setEnvSearchFocused}/>}
                 trigger={"hover"}
                 open={environmentsOpen}
                 onOpenChange={onOpenChange}>
          <li onMouseLeave={event => {
            event.preventDefault();
            event.stopPropagation();
          }}>
            <a href={TdmUrl.environments.environments}>
              <i className="icon icon-user-config"></i><span>{t("My environments")}</span>
            </a>
          </li>
        </Popover>
        <li>
          <a href={`/users/edit`}><i className="icon icon-user-config"></i><span>{t("Edit Profile")}</span></a>
        </li>
        <li>
          <a href="/account/logout"><i className="icon icon-logout"></i><span>{t("Log out")}</span></a>
        </li>
      </ul>
    </div>
  );
};

export function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export default AccountWidget;