import {ResourceType} from "./ResourceType";
import {AccessLevel} from "./ResourceAccessLevel";
import {HttpConnection} from "../Designer/RequestCreator";

export class ResourcePermissionController {
  public async get(resourceType: ResourceType, resourceId: string): Promise<{ [userId: string]: AccessLevel }> {
    return await HttpConnection.getAsync(null, `acl?type=${resourceType}&id=${resourceId}`, true);
  }

  public async set(resourceType: ResourceType, resourceId: string, permissions: { [userId: string]: AccessLevel }) {
    return await HttpConnection.postAsync(permissions, `acl?type=${resourceType}&id=${resourceId}`, true);
  }
}