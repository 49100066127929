export class Cookie {
  static getCookie(name: string) {
    const result = decodeURIComponent(document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null);
    return result == "null" ? undefined : result;
  }

  static addCookie(name: string, value: string, global: boolean = false) {
    document.cookie = `${name}=${value}; ${global ? "path=/" : ""}`;
  }

  static deleteCookie(name: string) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}