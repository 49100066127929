import {HttpConnection} from "../Designer/RequestCreator";
import type {Dayjs} from 'dayjs';
import dayjs from "dayjs";
import {CspResultDto} from "shared/Generated/Dto/CspResultDto";

export class UtilController {

  public async utcTime(): Promise<Dayjs> {
    const stringValue = await HttpConnection.getAsync(null, "utctime", false);
    return dayjs(stringValue);
  }

  public async getCspHeaders(url: string): Promise<CspResultDto> {
    return await HttpConnection.getAsync(url, "FindCspHeader", false) as CspResultDto;
  }
}