import {JsonApiController} from "./JsonApiController";
import {HttpConnection} from "../Designer/RequestCreator";
import {PageFolderDto} from "shared/Generated/Dto/PageFolderDto";

export class PageFolderApiController extends JsonApiController<PageFolderDto> {
  public constructor() {
    super("pageFolders");
  }

  public async getBreadCrumb(folderId: string): Promise<PageFolderDto[]> {
    return await HttpConnection.getAsync("", `${this.route}/${folderId}/breadCrumb`) as Promise<PageFolderDto[]>;
  }
}

