import React, {ReactNode, useState} from 'react';
import styles from './ComingSoon.scss';
import {t} from "i18next";

interface props {
  children: ReactNode
  functionalityName?: string
}

const ComingSoon: React.FC<props> = ({children, functionalityName}) => {
  const [isHovered, setHovered] = useState<boolean>(false);

  return (
    <div className={styles.container}
         onMouseEnter={() => setHovered(true)}
         onMouseLeave={() => setHovered(false)}>
      {isHovered &&
          <div className={styles.overlay}>
              <p className={styles.text}>{t("{{functionality}} Coming Soon", {functionality: functionalityName})}</p>
          </div>
      }
      {children}
    </div>
  );
};

export default ComingSoon;