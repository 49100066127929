import {JsonApiController} from "./JsonApiController";
import {HttpConnection} from "../Designer/RequestCreator";

export class EnvironmentInvitationApiController<EnvironmentInvitationDto> extends JsonApiController<EnvironmentInvitationDto> {
  constructor(path: string) {
    super(path);
  }

  public get(id: string): Promise<EnvironmentInvitationDto> {
    return HttpConnection.getAsync(id, "invitations/json", false) as Promise<EnvironmentInvitationDto>;
  }

  public postMultiple(dto: EnvironmentInvitationDto[], environment: string): Promise<EnvironmentInvitationDto[]> {
    return HttpConnection.postAsync(dto, `${environment}/${this.route}/post`, false) as Promise<EnvironmentInvitationDto[]>;
  }

  public async resendInvitation(invitationId: string) {
    return await HttpConnection.postAsync(invitationId, `${this.route}/resendInvitation`);
  }
}