import {JsonApiController} from "./JsonApiController";
import {ConnectionDto} from "shared/Generated/Dto/Connections/ConnectionDto";
import {HttpConnection} from "../Designer/RequestCreator";
import {ConnectionsResultDto} from "shared/Generated/Dto/ConnectionsResultDto";

export class ConnectionController extends JsonApiController<ConnectionDto> {
  public constructor() {
    super("connections");
  }

  public async getStorageProviders(): Promise<ConnectionDto[]> {
    return await HttpConnection.getAsync(null, `${this.route}/GetStorageProviders`) as ConnectionDto[];
  }

  public async createBlueAndRedConnection(): Promise<ConnectionDto> {
    return await HttpConnection.getAsync(null, `${this.route}/CreateBlueAndRedConnection`) as ConnectionDto;
  }

  public async GetConnectedServicesFromConnection(connectionId: string): Promise<ConnectionsResultDto[]> {
    return await HttpConnection.getAsync(connectionId, `${this.route}/GetConnectedServicesFromConnection`) as ConnectionsResultDto[];
  }

  public async getOAuthLogInURl(selectedType: ConnectionDto["$type"], extraOptions?: string): Promise<string> {
    return await HttpConnection.getAsync(null, `${this.route}/getOauthLoginUrl?redirectUrl=${encodeURI(window.location.href.replace("#_=_", ""))}&environmentPath=${HttpConnection.environmentPath}&typeName=${selectedType}${extraOptions ? `&extraInfo=${extraOptions}` : ""}`, false) as string;
  }
  
  public async login(urlParams: string): Promise<string> {
    return await HttpConnection.getAsync(null, `connections/login${urlParams}`, false) as string
  }
}

