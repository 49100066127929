//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LicensePermission {
  PlaySignage = 1,
  CreateEnvironment = 2,
  CreateMultipleEnvironments = 3,
  Interactivity = 4,
  ContentLog = 5,
  MailToSignage = 6,
  WindowsPlayers = 7,
  PowerBi = 8,
  ProofOfPlay = 9,
  CustomFonts = 10,
  CustomDataSources = 100,
  RealWorks = 101,
  Sharepoint = 102,
  Canva = 103,
  AzureSso = 104,
  Zermelo = 105,
  Amadeus = 106
}
